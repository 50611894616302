<template>
    <div class="page">
        <!--查询框-->
        <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
            <el-form ref="searchForm" :inline="true" :model="query" size="medium">
                <el-form-item prop="parkingLotId">
                    <SuggestParking v-model="query.parkingLotId" class="width260" />
                </el-form-item>
                <el-form-item prop="userName">
                    <el-input v-model="query.userName" placeholder="请输入商户名称" />
                </el-form-item>
                <el-form-item prop="mobileNum">
                    <el-input v-model="query.mobileNum" placeholder="请输入商户手机号" />
                </el-form-item>
                <el-form-item prop="driveType" label="充值时间">
                    <!--            :default-time="['00:00:00', '23:59:59']"-->
                    <el-date-picker v-model="query.timeValues" :picker-options="pickerOptions" align="right"
                        end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange"
                        value-format="yyyy-MM-dd" @change="changeDate">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">搜索</el-button>
                    <el-button @click="resetQueryForm">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content-box section main-box">
            <!-- <el-button @click="handleAdd" type="primary" style="margin-bottom: 10px;">新增</el-button> -->
            <div>
                <!--        v-loading="isLoading"-->
                <el-table :data="dataList" border class="common-table" size="medium" style="width: 100%">
                    <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                    <el-table-column align="left" label="商户名" prop="userName" width="180">
                    </el-table-column>
                    <el-table-column align="left" label="手机号" prop="mobileNumber" width="180">
                    </el-table-column>
                    <el-table-column align="left" label="充值金额" prop="changeAmount">
                    </el-table-column>
                    <el-table-column align="left" label="充值前金额" prop="startAmount">
                    </el-table-column>
                    <el-table-column align="left" label="充值后金额" prop="endAmount">
                    </el-table-column>
                    <el-table-column align="left" label="充值时间" prop="createTime">
                    </el-table-column>
                    <!-- <el-table-column align="left" label="备注" prop="remark" >
                    </el-table-column> -->
                    <!-- <el-table-column align="left" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button class="common-text" size="small" type="text" @click="goDelete(scope.row.id)">删除
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <div class="pagination-box">
                <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
                    :total="pagination.totalSize" background layout="sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import {
    getMerchantAccountChargePage,
} from "@/api/user";
import SuggestParking from "@/components/SuggestParking";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import { dateFormat } from "@/utils/utils";


export default {
    name: "parking_order_inside",
    mixins: [BaseMixin, Permission],
    data() {
        const today = dateFormat("yyyy-mm-dd", new Date());
        const beginDate = today;
        // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
        const endDate = today;
        return {
            parkingGateList: [],
            dataList: [],
            summaryData: {},
            // isLoading: false,
            completeDialogFormVisible: false, //控制完结弹窗
            modifyDialogFormVisible: false, //修改车牌弹窗
            total: "0",
            discountAmountStats: "0",
            receivableAmountStats: "0",
            transactionAmountStats: "0",
            // 完结表单
            completeForm: {
                parkingOrderIntoId: "",
                endDate: "",
            },
            rules: {
                endDate: [
                    { required: true, message: "请选择日期时间", trigger: "change" },
                ],
            },
            //修改车牌表单
            modifyForm: {
                parkingOrderIntoId: "",
                parkingSpaceId: "",
                color: "",
                numPlate: "",
                reason: "",
            },
            modifyRules: {
                color: [
                    { required: true, message: "请选择车牌颜色", trigger: "change" },
                ],
                numPlate: [
                    { required: true, message: "请输入车牌号码", trigger: "blur" },
                ],
                reason: [
                    { required: true, message: "请输入修改原因", trigger: "blur" },
                    { max: 100, message: "长度不能超过100个字符", trigger: "blur" },
                ],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 90
                        || time.getTime() > new Date().getTime();
                },
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            dialogFormVisible: false, //控制审核弹窗层显示
            photoDialogFormVisible: false,
            orderType: {
                // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
                0: "临停",
                1: "白名单",
                2: "黑名单",
                3: "长租",
                4: "预存",
                5: "访客",
                6: "共享",
            },
            orderStatus: {
                0: "正常",
                // 1: "无效",
                // 2: "审核",
                // 3: "复议",
                // "": "正常",
            },
            payStatus: {
                // "": "",
                '0': "欠费",
                '1': "不欠费",
                '2': "其他",
            },
            query: {
                parkingLotId: "", //停车场id
                operationType: "", //抬落杆类型
                parkingLotGateId: "",
                timeValues: [beginDate, endDate], //时间
                startDate: "",
                endDate: "",
            },
            exportDialogFormVisible: false, //导出弹窗
            form: {
                exportSize: 1,
            },
        };
    },
    components: {
        SuggestParking,
    },
    methods: {
        onSubmit() {
            console.log("submit!");
        },
        changeDate(e) {
            this.query.startDate = e[0];
            this.query.endDate = e[1];
        },

        //重置查询条件
        resetQueryForm() {
            const today = dateFormat("yyyy-mm-dd", new Date());
            const beginDate = today;
            // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
            const endDate = today;
            this.query = {
                parkingLotId: "", //停车场id
                operationType: "", //抬落杆类型
                parkingLotGateId: "",
                timeValues: [beginDate, endDate], //时间
                startDate: "",
                endDate: "",
            };
            this.query.timeValues = [];
            this.pagination.pageSize = 10;
            this.getNowDate();
            this.doSearch();
        },
        doSearch() {
            this.pagination.pageNo = 1;
            this.search();
        },
        handleAdd() {
            this.$router.push("/business_manage/add_visit");
        },
        async search() {
            this.isLoading = true;
            const params = this.paramFormat(this.query);
            if (this.query.timeValues != null) {
                if (this.query.driveType == '') {
                    this.$message.warning('请选择时间范围后重试')
                    return false
                }
                params["startDate"] = this.query.timeValues[0];
                params["endDate"] = this.query.timeValues[1];
            }
            const res = await getMerchantAccountChargePage(params);
            // const res = await getParkingOrderByPage({
            //   pageNo: this.pagination.pageNo,
            //   pageSize: this.pagination.pageSize,
            //   userId: "1",
            // });
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                if (returnObject !== "") {
                    this.dataList = returnObject.records;
                    this.summaryData = returnObject
                    this.pagination.totalSize = returnObject.total;
                    this.pagination.totalPages = returnObject.pages;
                    this.total = returnObject.total;
                    this.form.exportSize = returnObject.total;
                }
            }

        },
        //获取当前时间
        getNowDate() {
            const beginToday = dateFormat("yyyy-mm-dd", new Date());
            const beginDate = beginToday;
            const endToday = dateFormat("yyyy-mm-dd", new Date());
            const endDate = endToday;
            this.query.timeValues = [beginDate, endDate];
        },
    },
};
</script>

<style lang="less" scoped>
.photoShow {
    display: flex;
    flex-direction: row;

    .photo-width {
        /deep/ .el-carousel__container {
            width: 430px;
        }
    }

    .driveInPhoto {
        margin: 0 34px;
    }

    .driveOutPhoto {
        margin: 0 26px;
    }
}

.main-box {
    margin-top: 20px;
}

/deep/ .el-button--text {
    color: #0768FD !important;
}
</style>